.text-file-reader {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
}

h2 {
    text-align: center;
    color: #333;
}

.input-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}

.input-field, .file-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(33% - 10px);
    box-sizing: border-box;
}

.file-input {
    width: 100%;
}

.output-section {
    margin-top: 20px;
}

.output-section h3 {
    color: #555;
    font-size: 18px;
    margin-bottom: 10px;
}

.output-list {
    padding-left: 20px;
    list-style-position: inside;
}

.output-list li {
    width: 100%;
    display: flex;
    padding: 8px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 8px;
    box-sizing: border-box;
}

.text-file-reader {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tabs button {
    padding: 12px 25px;
    margin-right: 10px;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .tabs button:hover {
    background-color: #ddd;
  }
  
  .active-tab {
    background-color: #007bff;
    color: red;
  }
  
  .tab-content-section {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .input-section {
    display: flex;
    flex-direction: column;
  }
  
  .input-field, .file-input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .output-list {
    padding-left: 20px;
  }
  
  .output-list li {
    margin: 5px 0;
  }
  